import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class PaymentService {
  private apiPrefix = '/client/payments';

  constructor(
    private api: ApiService,
  ) {
  }

  createPayment() {
    return this.api.post(`${this.apiPrefix}/web`, {});
  }

  checkPayment(payId: string) {
    return this.api.patch(`${this.apiPrefix}/web`, {payId});
  }
}
